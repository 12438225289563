const iconLinksBlack = {
    behance: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:26:24.377Z_18428c82-ebcb-4a63-9f0f-6beb2ee11026',
    coffee: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:26:44.908Z_55f58ebd-5bdd-44f3-9f18-0fd518a4665f',
    diaspora: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:27:10.648Z_3f65a699-3c98-4f6a-9f2f-9da1f40fd0f7',
    facebook: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:27:59.871Z_1e1e577b-79c3-405b-93e1-4676857c4b9a',
    github: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:13.756Z_8b87990c-c0f1-4964-a151-a23e84ce2095',
    gitlab: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:34.932Z_8ac01c5d-61e7-490b-b273-25773e4dd25f',
    gitter: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:47.947Z_ead866ff-fa7c-4a14-a629-77d6c8c6cd70',
    heart: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:59.427Z_a87699b4-054c-46f0-b41d-ffa3a8a4a3fe',
    instagram: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:15.830Z_fd078727-f6fe-414e-8226-28ae4cabd534',
    linkedin: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:28.491Z_84cc5ce2-d45a-4995-ac2b-239bc40b72b5',
    mastodon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:43.677Z_ad5e7c92-866e-4f43-a897-57984e948e74',
    menu: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:59.613Z_1f61506f-3b54-45b1-b74f-54be2b4aa27a',
    ok: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:30:13.068Z_3d9d81dd-e78a-4e4e-886d-3be4d19093eb',
    reddit: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:30:27.268Z_a0d90373-f846-494f-881d-c6a9750eb8ee',
    skype: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:30:50.676Z_eb8a6fc1-ad4f-4d1a-af17-e9eeae0dd4e8',
    slack: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:06.146Z_405327e7-aa1f-4a71-8001-deaea5af08d8',
    stackoverflow: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:23.538Z_7bc449b2-08de-4fac-bbc0-37e9797977da',
    telegram: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:37.960Z_759375eb-50b8-472e-aa92-f0b1cfd05a8b',
    vk: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:09.724Z_a702319f-633e-452d-8f58-8b81a38a7838',
    whatsapp: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:25.512Z_62b025d8-9080-4cf8-b167-2aeae6f90255',
    www: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-27T06:44:11.163Z_30a34ae2-89c1-4bd4-8acb-fc7c1555324b',
    x: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T16:21:33.518Z_a49fd59c-cfcb-43d8-9d0b-ec528e2991b4',
    xing: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:42.328Z_4904923c-b82a-4ed2-be58-c55994aadaa9',
    youtube: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:54.794Z_9889edae-4a1d-4d77-b039-ed78e756ce99',
  };

  const iconLinksWhite = {
    behance: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:04.528Z_89f82a62-b75f-4a10-bf64-1dd104e7971f',
    coffee: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:21.783Z_f43f83f2-e276-489d-8303-8a8d0cc50952',
    diaspora: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:36.238Z_bd6531f3-b3bd-4f58-810d-4e471cd96825',
    facebook: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:07.326Z_733c7249-76cf-47cd-81d3-dc01fd7c16e2',
    github: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:19.479Z_4bb18b73-1e2e-42b4-bf41-deca918720b2',
    gitlab: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:43.815Z_7f8619a8-dc0a-48e2-9f21-45485b0b9208',
    gitter: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:58.102Z_a50622d2-1935-4b1e-9f93-b602d6c43e97',
    heart: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:37:12.618Z_2b59cbe8-8282-4dd8-b093-8bbd02e8b5dc',
    instagram: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:37:34.722Z_44df56f6-3301-4c62-bcd6-df220d761715',
    linkedin: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:37:51.133Z_d9298197-8198-4847-919b-24e5c169aa98',
    mastodon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:38:19.128Z_3194a01d-1934-40a9-8358-a14fea059373',
    menu: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:38:34.502Z_cdb2cc08-8b5d-4091-894d-3c3110d74182',
    ok: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:38:47.805Z_3ac145d3-eea4-4d63-ace4-d227bea6b652',
    reddit: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:02.420Z_2e146e92-4698-4c5c-a22b-e9fb8c24d9f7',
    skype: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:18.012Z_3e4ff1d6-5816-4731-a078-b35734845dd6',
    slack: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:34.053Z_50381480-4ffe-4856-bcab-f7fc4ed1f558',
    stackoverflow: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:54.077Z_9ac562de-4043-4285-940b-9c1b987c3184',
    telegram: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:40:13.045Z_e66c97a0-c527-48c5-9906-c794e54a880b',
    vk: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:07.102Z_47c8dd04-8004-4e4c-b2a9-0f0fc360f6b0',
    whatsapp: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:22.167Z_057d568f-0879-4e49-994d-48295440e8fa',
    www: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-27T06:44:15.951Z_7d6dc70f-284c-410c-9d93-a9ee4f8a40df',
    x: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T16:21:38.919Z_54227580-dd6d-4678-a700-2786ad5d85cc',
    xing: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:37.633Z_944dc88c-4d5e-4b63-a700-8ff42192d4db',
    youtube: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:51.250Z_68f28ef9-abab-4ce7-b2c4-b6d4c6e02b84',
  };

export const templatesFormData = {
    template1: {
      selectedTemplateName: 'template1',
        phoneIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-19T10:44:41.883Z_327f5481-7a57-429d-b498-12c538396cfb',
        mobileIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-19T10:44:37.169Z_bb1b64aa-076b-4a01-9028-7a5a7853a022',
        emailIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-19T10:44:46.718Z_d3ef23a3-81d3-49ab-ab39-e981c450ae5e',
        mainColor: '#000000',
        secondaryColor: '#000000',
        descriptionBackground: 'transparent',
        borderColor: '#D9D9D9',
        signatureWidth: 480,
        profileImageSize: 80,
        profileImageShape: 50,
        fontSize: 'medium',
        fontFamily: 'Arial, sans-serif',
        socialIconText: false,
        socialIconColor: '#3e63dd',
        addonsColor: '#9F9F9F',
        chatColor: '#3e63dd',
        bannerWidth: 480,
        meetingWidth: 140,
        meetingChat: {
          meetingImageUrl: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-10-31T15:05:03.133Z_dfbbaf72-8538-4476-9819-b2ad5e383808',
          meetingImageHref: '',
          chatTextIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-19T10:41:19.095Z_87b23a3a-5f16-4379-9442-5c3ac9a66fa3',
          chatText: 'Schedule your free consultation',
          chatTextHref: '',
        },
        selectedTemplate: 'template1',
        iconLinksBlack: {
        behance: iconLinksBlack.behance,
        coffee: iconLinksBlack.coffee,
        diaspora: iconLinksBlack.diaspora,
        facebook: iconLinksBlack.facebook,
        github: iconLinksBlack.github,
        gitlab: iconLinksBlack.gitlab,
        gitter: iconLinksBlack.gitter,
        heart: iconLinksBlack.heart,
        instagram: iconLinksBlack.instagram,
        linkedin: iconLinksBlack.linkedin,
        mastodon: iconLinksBlack.mastodon,
        menu: iconLinksBlack.menu,
        ok: iconLinksBlack.ok,
        reddit: iconLinksBlack.reddit,
        skype: iconLinksBlack.skype,
        slack: iconLinksBlack.slack,
        stackoverflow: iconLinksBlack.stackoverflow,
        telegram: iconLinksBlack.telegram,
        vk: iconLinksBlack.vk,
        whatsapp: iconLinksBlack.whatsapp,
        www: iconLinksBlack.www,
        x: iconLinksBlack.x,
        xing: iconLinksBlack.xing,
        youtube: iconLinksBlack.youtube,
        },
        iconLinksWhite: {
        behance: iconLinksWhite.behance,
        coffee: iconLinksWhite.coffee,
        diaspora: iconLinksWhite.diaspora,
        facebook: iconLinksWhite.facebook,
        github: iconLinksWhite.github,
        gitlab: iconLinksWhite.gitlab,
        gitter: iconLinksWhite.gitter,
        heart: iconLinksWhite.heart,
        instagram: iconLinksWhite.instagram,
        linkedin: iconLinksWhite.linkedin,
        mastodon: iconLinksWhite.mastodon,
        menu: iconLinksWhite.menu,
        ok: iconLinksWhite.ok,
        reddit: iconLinksWhite.reddit,
        skype: iconLinksWhite.skype,
        slack: iconLinksWhite.slack,
        stackoverflow: iconLinksWhite.stackoverflow,
        telegram: iconLinksWhite.telegram,
        vk: iconLinksWhite.vk,
        whatsapp: iconLinksWhite.whatsapp,
        www: iconLinksWhite.www,
        x: iconLinksWhite.x,
        xing: iconLinksWhite.xing,
        youtube: iconLinksWhite.youtube,
        },
    },
    template2: {
      selectedTemplateName: 'template2',
        phoneIcon: '',
        mobileIcon: '',
        emailIcon: '',
        mainColor: '#000000',
        secondaryColor: '#000000',
        descriptionBackground: 'transparent',
        borderColor: '#111111',
        signatureWidth: 480,
        profileImageSize: 100,
        profileImageShape: 0,
        fontSize: 'medium',
        fontFamily: `'Courier New', monospace`,
        socialIconText: false,
        socialIconColor: '#111111',
        addonsColor: '#9F9F9F',
        chatColor: '#111111',
        bannerWidth: 480,
        meetingWidth: 150,
        meetingChat: {
          meetingImageUrl: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-10-31T15:05:08.419Z_89ab38fe-f327-4d98-95d8-931770bf046e',
          meetingImageHref: '',
          chatTextIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T18:36:25.835Z_7879fd4d-878e-41e6-8376-754b837b9fda',
          chatText: 'Schedule your free consultation',
          chatTextHref: '',
        },
        selectedTemplate: 'template2',
        iconLinksBlack: {
        behance: iconLinksBlack.behance,
        coffee: iconLinksBlack.coffee,
        diaspora: iconLinksBlack.diaspora,
        facebook: iconLinksBlack.facebook,
        github: iconLinksBlack.github,
        gitlab: iconLinksBlack.gitlab,
        gitter: iconLinksBlack.gitter,
        heart: iconLinksBlack.heart,
        instagram: iconLinksBlack.instagram,
        linkedin: iconLinksBlack.linkedin,
        mastodon: iconLinksBlack.mastodon,
        menu: iconLinksBlack.menu,
        ok: iconLinksBlack.ok,
        reddit: iconLinksBlack.reddit,
        skype: iconLinksBlack.skype,
        slack: iconLinksBlack.slack,
        stackoverflow: iconLinksBlack.stackoverflow,
        telegram: iconLinksBlack.telegram,
        vk: iconLinksBlack.vk,
        whatsapp: iconLinksBlack.whatsapp,
        www: iconLinksBlack.www,
        x: iconLinksBlack.x,
        xing: iconLinksBlack.xing,
        youtube: iconLinksBlack.youtube,
        },
        iconLinksWhite: {
        behance: iconLinksWhite.behance,
        coffee: iconLinksWhite.coffee,
        diaspora: iconLinksWhite.diaspora,
        facebook: iconLinksWhite.facebook,
        github: iconLinksWhite.github,
        gitlab: iconLinksWhite.gitlab,
        gitter: iconLinksWhite.gitter,
        heart: iconLinksWhite.heart,
        instagram: iconLinksWhite.instagram,
        linkedin: iconLinksWhite.linkedin,
        mastodon: iconLinksWhite.mastodon,
        menu: iconLinksWhite.menu,
        ok: iconLinksWhite.ok,
        reddit: iconLinksWhite.reddit,
        skype: iconLinksWhite.skype,
        slack: iconLinksWhite.slack,
        stackoverflow: iconLinksWhite.stackoverflow,
        telegram: iconLinksWhite.telegram,
        vk: iconLinksWhite.vk,
        whatsapp: iconLinksWhite.whatsapp,
        www: iconLinksWhite.www,
        x: iconLinksWhite.x,
        xing: iconLinksWhite.xing,
        youtube: iconLinksWhite.youtube,
        },
    },
    template3: {
      selectedTemplateName: 'template3',
        phoneIcon: '',
        mobileIcon: '',
        emailIcon: '',
        mainColor: '#4D3EC6',
        secondaryColor: '#000000',
        descriptionBackground: 'transparent',
        borderColor: '#D9D9D9',
        signatureWidth: 480,
        profileImageSize: 80,
        profileImageShape: 50,
        fontSize: 'small',
        fontFamily: 'Verdana, sans-serif',
        socialIconText: false,
        socialIconColor: '#4D3EC6',
        addonsColor: '#9F9F9F',
        chatColor: '#4D3EC6',
        bannerWidth: 480,
        meetingWidth: 170,
        meetingChat: {
          meetingImageUrl: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-10-31T15:05:15.581Z_e92cdda9-2bf0-49a2-bb7b-47dcd40348f7',
          meetingImageHref: '',
          chatTextIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T19:00:13.235Z_92c35e3d-da95-4423-8e5b-3ca6856d85a3',
          chatText: 'Schedule your free consultation',
          chatTextHref: '',
        },
        selectedTemplate: 'template3',
        iconLinksBlack: {
        behance: iconLinksBlack.behance,
        coffee: iconLinksBlack.coffee,
        diaspora: iconLinksBlack.diaspora,
        facebook: iconLinksBlack.facebook,
        github: iconLinksBlack.github,
        gitlab: iconLinksBlack.gitlab,
        gitter: iconLinksBlack.gitter,
        heart: iconLinksBlack.heart,
        instagram: iconLinksBlack.instagram,
        linkedin: iconLinksBlack.linkedin,
        mastodon: iconLinksBlack.mastodon,
        menu: iconLinksBlack.menu,
        ok: iconLinksBlack.ok,
        reddit: iconLinksBlack.reddit,
        skype: iconLinksBlack.skype,
        slack: iconLinksBlack.slack,
        stackoverflow: iconLinksBlack.stackoverflow,
        telegram: iconLinksBlack.telegram,
        vk: iconLinksBlack.vk,
        whatsapp: iconLinksBlack.whatsapp,
        www: iconLinksBlack.www,
        x: iconLinksBlack.x,
        xing: iconLinksBlack.xing,
        youtube: iconLinksBlack.youtube,
        },
        iconLinksWhite: {
        behance: iconLinksWhite.behance,
        coffee: iconLinksWhite.coffee,
        diaspora: iconLinksWhite.diaspora,
        facebook: iconLinksWhite.facebook,
        github: iconLinksWhite.github,
        gitlab: iconLinksWhite.gitlab,
        gitter: iconLinksWhite.gitter,
        heart: iconLinksWhite.heart,
        instagram: iconLinksWhite.instagram,
        linkedin: iconLinksWhite.linkedin,
        mastodon: iconLinksWhite.mastodon,
        menu: iconLinksWhite.menu,
        ok: iconLinksWhite.ok,
        reddit: iconLinksWhite.reddit,
        skype: iconLinksWhite.skype,
        slack: iconLinksWhite.slack,
        stackoverflow: iconLinksWhite.stackoverflow,
        telegram: iconLinksWhite.telegram,
        vk: iconLinksWhite.vk,
        whatsapp: iconLinksWhite.whatsapp,
        www: iconLinksWhite.www,
        x: iconLinksWhite.x,
        xing: iconLinksWhite.xing,
        youtube: iconLinksWhite.youtube,
        },
    },
    template4: {
      selectedTemplateName: 'template4',
        phoneIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T18:40:17.593Z_c0421c81-b210-42ac-bdd0-650f65869cf1',
        mobileIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T18:40:00.975Z_6998a4a0-71c2-452a-afcb-cf7cff8c5b55',
        emailIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T18:40:28.371Z_ea8d0e8d-a930-4521-8e4c-c1b32e17f618',
        mainColor: '#000000',
        secondaryColor: '#000000',
        descriptionBackground: 'transparent',
        borderColor: '#D9D9D9',
        signatureWidth: 480,
        profileImageSize: 80,
        profileImageShape: 5,
        fontSize: 'small',
        fontFamily: 'Tahoma, sans-serif',
        socialIconText: true,
        socialIconSize: 14,
        socialIconShape: 50,
        socialIconColor: '#111111',
        addonsColor: '#9F9F9F',
        chatColor: '#111111',
        bannerWidth: 480,
        meetingWidth: 150,
        meetingChat: {
          meetingImageUrl: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-10-31T15:05:23.615Z_02bc669e-da86-4e24-afa5-0635fe719a63',
          meetingImageHref: '',
          chatTextIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T19:11:57.258Z_5a3d6f77-a226-453d-bf7b-22987245c303',
          chatText: 'Schedule your free consultation',
          chatTextHref: '',
        },
        selectedTemplate: 'template4',
        iconLinksBlack: {
        behance: iconLinksBlack.behance,
        coffee: iconLinksBlack.coffee,
        diaspora: iconLinksBlack.diaspora,
        facebook: iconLinksBlack.facebook,
        github: iconLinksBlack.github,
        gitlab: iconLinksBlack.gitlab,
        gitter: iconLinksBlack.gitter,
        heart: iconLinksBlack.heart,
        instagram: iconLinksBlack.instagram,
        linkedin: iconLinksBlack.linkedin,
        mastodon: iconLinksBlack.mastodon,
        menu: iconLinksBlack.menu,
        ok: iconLinksBlack.ok,
        reddit: iconLinksBlack.reddit,
        skype: iconLinksBlack.skype,
        slack: iconLinksBlack.slack,
        stackoverflow: iconLinksBlack.stackoverflow,
        telegram: iconLinksBlack.telegram,
        vk: iconLinksBlack.vk,
        whatsapp: iconLinksBlack.whatsapp,
        www: iconLinksBlack.www,
        x: iconLinksBlack.x,
        xing: iconLinksBlack.xing,
        youtube: iconLinksBlack.youtube,
        },
        iconLinksWhite: {
        behance: iconLinksWhite.behance,
        coffee: iconLinksWhite.coffee,
        diaspora: iconLinksWhite.diaspora,
        facebook: iconLinksWhite.facebook,
        github: iconLinksWhite.github,
        gitlab: iconLinksWhite.gitlab,
        gitter: iconLinksWhite.gitter,
        heart: iconLinksWhite.heart,
        instagram: iconLinksWhite.instagram,
        linkedin: iconLinksWhite.linkedin,
        mastodon: iconLinksWhite.mastodon,
        menu: iconLinksWhite.menu,
        ok: iconLinksWhite.ok,
        reddit: iconLinksWhite.reddit,
        skype: iconLinksWhite.skype,
        slack: iconLinksWhite.slack,
        stackoverflow: iconLinksWhite.stackoverflow,
        telegram: iconLinksWhite.telegram,
        vk: iconLinksWhite.vk,
        whatsapp: iconLinksWhite.whatsapp,
        www: iconLinksWhite.www,
        x: iconLinksWhite.x,
        xing: iconLinksWhite.xing,
        youtube: iconLinksWhite.youtube,
        },
    },
    template5: {
      selectedTemplateName: 'template5',
        phoneIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T19:21:15.704Z_593d024a-57e1-41b2-89c1-348869dc4318',
        mobileIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T19:21:08.409Z_aeadc6f1-7a18-4ce4-b0d2-bf467c5cd361',
        emailIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T19:21:22.922Z_06315656-a6db-460c-813a-923989146dfb',
        mainColor: '#000000',
        secondaryColor: '#000000',
        descriptionBackground: '#F1FAF7',
        borderColor: '#111111',
        signatureWidth: 480,
        profileImageSize: 80,
        profileImageShape: 50,
        fontSize: 'medium',
        fontFamily: `'Georgia', serif`,
        socialIconText: false,
        socialIconColor: '#2A8B6E',
        addonsColor: '#9F9F9F',
        chatColor: '#2A8B6E',
        bannerWidth: 480,
        meetingWidth: 150,
        meetingChat: {
          meetingImageUrl: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-10-31T15:05:29.170Z_76aca25c-b19d-491c-be4e-d43252bc5c97',
          meetingImageHref: '',
          chatTextIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T19:21:35.940Z_abfe5a5f-406b-4a29-ab37-eb8032fa68f4',
          chatText: 'Schedule free consultation',
          chatTextHref: '',
        },
        selectedTemplate: 'template5',
        iconLinksBlack: {
        behance: iconLinksBlack.behance,
        coffee: iconLinksBlack.coffee,
        diaspora: iconLinksBlack.diaspora,
        facebook: iconLinksBlack.facebook,
        github: iconLinksBlack.github,
        gitlab: iconLinksBlack.gitlab,
        gitter: iconLinksBlack.gitter,
        heart: iconLinksBlack.heart,
        instagram: iconLinksBlack.instagram,
        linkedin: iconLinksBlack.linkedin,
        mastodon: iconLinksBlack.mastodon,
        menu: iconLinksBlack.menu,
        ok: iconLinksBlack.ok,
        reddit: iconLinksBlack.reddit,
        skype: iconLinksBlack.skype,
        slack: iconLinksBlack.slack,
        stackoverflow: iconLinksBlack.stackoverflow,
        telegram: iconLinksBlack.telegram,
        vk: iconLinksBlack.vk,
        whatsapp: iconLinksBlack.whatsapp,
        www: iconLinksBlack.www,
        x: iconLinksBlack.x,
        xing: iconLinksBlack.xing,
        youtube: iconLinksBlack.youtube,
        },
        iconLinksWhite: {
        behance: iconLinksWhite.behance,
        coffee: iconLinksWhite.coffee,
        diaspora: iconLinksWhite.diaspora,
        facebook: iconLinksWhite.facebook,
        github: iconLinksWhite.github,
        gitlab: iconLinksWhite.gitlab,
        gitter: iconLinksWhite.gitter,
        heart: iconLinksWhite.heart,
        instagram: iconLinksWhite.instagram,
        linkedin: iconLinksWhite.linkedin,
        mastodon: iconLinksWhite.mastodon,
        menu: iconLinksWhite.menu,
        ok: iconLinksWhite.ok,
        reddit: iconLinksWhite.reddit,
        skype: iconLinksWhite.skype,
        slack: iconLinksWhite.slack,
        stackoverflow: iconLinksWhite.stackoverflow,
        telegram: iconLinksWhite.telegram,
        vk: iconLinksWhite.vk,
        whatsapp: iconLinksWhite.whatsapp,
        www: iconLinksWhite.www,
        x: iconLinksWhite.x,
        xing: iconLinksWhite.xing,
        youtube: iconLinksWhite.youtube,
        },
    },
  };
  