<template>
  <div :class="['app', { 'dark-mode': isDarkMode }]">
    <div v-if="isMobile && navOpen" class="overlay" @click="toggleNav"></div>
    <main class="content">
      <div v-if="isLoading" class="loader-container">
        <Loader :isLoading="isLoading" />
      </div>
      <section v-if="!isLoading && !isPreview" class="editor">
        <button class="hamburger" @click="toggleNav" v-if="isMobile">
          <i class="fas fa-bars"></i>
        </button>

        <nav class="top-nav" :class="{ 'hidden': isMobile && !navOpen, 'visible': isMobile && navOpen }">
          <router-link to="/signature-templates" class="nav-tab" exact @click="handleNavClick">
            <i class="fas fa-clipboard-list"></i> Templates
          </router-link>
          <router-link to="/basic-info" class="nav-tab" exact @click="handleNavClick">
            <i class="fas fa-info-circle"></i> Basic Info
          </router-link>
          <router-link to="/signature-options" class="nav-tab" exact @click="handleNavClick">
            <i class="fas fa-cogs"></i> Options
          </router-link>
          <router-link to="/social-media" class="nav-tab" exact @click="handleNavClick">
            <i class="fas fa-share-alt"></i> Social Media
          </router-link>
          <router-link to="/signature-addons" class="nav-tab" exact @click="handleNavClick">
            <i class="fas fa-puzzle-piece"></i> Addons
          </router-link>
          <button class="nav-tab code-button" @click="showSaveModal">
            <i class="fas fa-code"></i> Save
          </button>
          <button class="nav-tab code-button" v-if="isDevMode" @click="showCodeModal">
            <i class="fas fa-code"></i> Code
          </button>
          <button v-if="isDevMode" @click="navigateTo(generatePreviewLink())" class="nav-tab preview-button">
            <i class="fas fa-eye"></i> Preview
          </button>
          <button v-if="isDevMode" @click="navigateTo(generateEditLink())" class="nav-tab settings-button">
            <i class="fas fa-edit"></i> Settings
          </button>
        </nav>

        <router-view
          v-if="!isLoading"
          :formData="formData"
          :selectedIcons="formData.selectedIcons"
          :socialIconText="socialIconText"
          :socialLinks="formData.socialLinks"
          :iconLinks="currentIconLinks"
          :profileImage="formData.profileImage"
          :additionalFields="formData.additionalFields"
          :customIcons="formData.customIcons"
          :logoImage="formData.logoImage"
          :showLogo="showLogo"
          :disclaimerWidth="disclaimerWidth"
          :logoSize="logoSize"
          :selectedTemplate="formData.selectedTemplate"
          :link="formData.link"
          :banner="formData.banner"
          :disclaimer="formData.disclaimer"
          :plannedAbsence="formData.plannedAbsence"
          :meetingChat="formData.meetingChat"
          :meetingWidth="formData.meetingWidth"
          :addonsColor="addonsColor"
          :chatColor="chatColor"
          :bannerWidth="bannerWidth"
          :isWhiteIcons="formData.isWhiteIcons"
          @update="updateFormData"
          @update-image="updateProfileImage"
          @update-logo-image="updateLogoImage"
          @update-icons="updateSelectedIcons"
          @add-custom-icon="addCustomIcon"
          @update-links="updateSocialLinks"
          @textColorChange="updateTextColor"
          @mainColorChange="updateMainColor"
          @secondaryColorChange="updateSecondaryColor"
          @descriptionBackgroundChange="updateDescriptionBackground"
          @borderColorChange="updateBorderColor"
          @signatureWidthChange="applySignatureWidth"
          @toggleSocialIconText="toggleSocialIconText"
          @socialIconColorChange="updateSocialIconColor"
          @plannedAbsenceColorChange="updatePlannedAbsenceColor"
          @plannedAbsenceTextColorChange="updatePlannedAbsenceTextColor"
          @toggleProfileImage="toggleProfileImage"
          @profileImageSizeChange="applyProfileImageSize"
          @profileImageShapeChange="applyProfileImageShape"
          @socialIconShapeChange="applySocialIconShape"
          @fontFamilyChange="applyFontFamily"
          @fontSizeChange="applyFontSize"
          @add-field="addAdditionalField"
          @remove-field="removeAdditionalField"
          @update-field-value="updateFieldValue"
          @template-selected="handleTemplateSelected"
          @toggle-logo="toggleLogo"
          @toggle-disclaimer="toggleDisclaimer"
          @logoSizeChange="applyLogoSize"
          @update-profile-image-href="updateProfileImageHref"
          @update-logo-image-href="updateLogoImageHref"
          @socialIconSizeChange="applySocialIconSize"
          @show-modal="showModal = true"
          @update-link="updateLink"
          @update-banner="updateBanner"
          @update-plannedAbsence="updatePlannedAbsence"
          @update-meetingChat="updateMeetingChat"
          @meetingWidthChange="updateMeetingWidth"
          @update-disclaimer="updateDisclaimer"
          @addonsColorChange="updateAddonsColor"
          @chatColorChange="updateChatColor"
          @bannerWidthChange="updateBannerWidth"
          @update-feedback="updateFeedback"
          @toggle-icon-color="toggleIconColor"
        ></router-view>
      </section>
      <section v-if="!isLoading" :class="['preview', { 'isPreview': isPreview }]">
        <a href="https://www.gsignature.com" target="_blank" rel="noopener noreferrer">
          <img class="logo" :src="currentLogo" alt="logo" />
        </a>
        <div class="email-client">
          <div class="title-bar">
            <div class="buttons">
              <div class="close"></div>
              <div class="minimize"></div>
              <div class="maximize"></div>
            </div>
            <div class="title">Email Preview</div>
            <div class="actions">
              <div class="undo-button"
                  @click="undo"
                  @mouseover="showToast('Undo last action')"
                  @mouseleave="hideToast"
                  :class="{ 'disabled': !canUndo }">
                <i class="fas fa-arrow-left"></i>
              </div>
              <div class="redo-button"
                  @click="redo"
                  @mouseover="showToast('Redo last action')"
                  @mouseleave="hideToast"
                  :class="{ 'disabled': !canRedo }">
                <i class="fas fa-arrow-right"></i>
              </div>
              <div class="reset-settings"
                  @click="confirmResetSettings"
                  @mouseover="showToast('Reset all settings')"
                  @mouseleave="hideToast">
                <i class="fas fa-undo-alt"></i>
              </div>
              <div class="dark-mode-toggle"
                  @click="toggleDarkMode"
                  @mouseover="showToast(isDarkMode ? 'Switch to light mode' : 'Switch to dark mode')"
                  @mouseleave="hideToast">
                <i :class="isDarkMode ? 'fas fa-sun' : 'fas fa-moon'"></i>
              </div>
            </div>
            <div v-if="showingToast" class="toast">
              {{ toastMessage }}
            </div>
          </div>
          <div class="toolbar">
            <i class="fas fa-reply"></i>
            <i class="fas fa-reply-all"></i>
            <i class="fas fa-share"></i>
            <i class="fas fa-trash"></i>
            <i class="fas fa-envelope-open-text"></i>
          </div>
          <div class="email-header">
            <div class="email-from">From: gSignature Generator</div>
            <div class="email-to">To: {{ previewEmail || 'recipient@gsignature.com' }}</div>
            <div class="email-subject">Title: {{ formData.subject || "Email Subject" }}</div>
            <div class="email-date">Date: {{ formData.date || "Jan 1, 2024" }}</div>
          </div>
          <div class="email-body">
            <signature-preview
              ref="signaturePreview"
              v-if="hasFormData"
              :name="formData.name"
              :jobTitle="formData.jobTitle"
              :companyName="formData.companyName"
              :companyAddress="formData.companyAddress"
              :phoneNumber="formData.phoneNumber"
              :phoneLabel="formData.phoneLabel"
              :phoneIcon="formData.phoneIcon"
              :mobileNumber="formData.mobileNumber"
              :mobileIcon="formData.mobileIcon"
              :mobileLabel="formData.mobileLabel"
              :emailAddress="formData.emailAddress"
              :emailLabel="formData.emailLabel"
              :emailIcon="formData.emailIcon"
              :mainColor="formData.mainColor"
              :secondaryColor="formData.secondaryColor"
              :signatureWidth="formData.signatureWidth"
              :selectedIcons="formData.selectedIcons"
              :socialIconText="formData.socialIconText"
              :socialLinks="formData.socialLinks"
              :iconLinks="currentIconLinks"
              :profileImage="formData.profileImage"
              :profileImageHref="formData.profileImageHref"
              :descriptionBackground="formData.descriptionBackground"
              :borderColor="formData.borderColor"
              :showProfileImage="formData.showProfileImage"
              :profileImageSize="formData.profileImageSize"
              :profileImageShape="formData.profileImageShape"
              :socialIconShape="formData.socialIconShape"
              :fontFamily="formData.fontFamily"
              :fontSize="formData.fontSize"
              :additionalFields="formData.additionalFields"
              :template="formData.selectedTemplate"
              :customIcons="formData.customIcons"
              :logoImage="formData.logoImage"
              :logoImageHref="formData.logoImageHref"
              :showLogo="formData.showLogo"
              :disclaimerWidth="formData.disclaimerWidth"
              :logoSize="formData.logoSize"
              :socialIconColor="formData.socialIconColor"
              :plannedAbsenceColor="formData.plannedAbsenceColor"
              :plannedAbsenceTextColor="formData.plannedAbsenceTextColor"
              :socialIconSize="formData.socialIconSize"
              :link="formData.link"
              :banner="formData.banner"
              :disclaimer="formData.disclaimer"
              :plannedAbsence="formData.plannedAbsence"
              :meetingChat="formData.meetingChat"
              :meetingWidth="formData.meetingWidth"
              :addonsColor="formData.addonsColor"
              :chatColor="formData.chatColor"
              :bannerWidth="formData.bannerWidth"
              :feedback="formData.feedback"
            ></signature-preview>
          </div>
        </div>
      </section>
    </main>

    <!-- Modal -->
    <ModalSave
      :show="showSaveModalFlag"
      :formData="formData"
      :code="generatedCode"
      :originalFormData="originalFormData"
      @close="hideSaveModalFlag"
      @send-email="handleSendEmail"
      @update-form-data-for-export="updateFormDataForExport"
      @restore-original-form-data="restoreOriginalFormData"
    ></ModalSave>
    <ModalAdd :show="showModal" @close="showModal = false" @add-field="handleAddField"></ModalAdd>
    <ModalCode :show="showCodeModalFlag" :code="generatedCode" @close="showCodeModalFlag = false"></ModalCode>
    <ModalReset :show="showResetModal" @close="showResetModal = false" @confirm="handleConfirmReset"></ModalReset>
    <SignaturePowered v-if="!isMobile" :isDarkMode="isDarkMode" />
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import SignaturePreview from '../../router/views/GeneratorPreview.vue';
import ModalAdd from '../../components/modals/ModalAdd.vue';
import ModalCode from '../../components/modals/ModalCode.vue';
import ModalReset from '../../components/modals/ModalReset.vue';
import ModalSave from '../../components/modals/ModalSave.vue';
import { saveAs } from 'file-saver';
import Loader from '../../components/loader/LoaderView.vue';
import axios from 'axios';
import { BASE_URL } from '../../config';
import SignaturePowered from '../../components/powered/SignaturePowered.vue';
import { templatesFormData } from '@/data/TemplatesFormData';

export default {
  name: 'App',
  components: {
    SignaturePreview,
    ModalAdd,
    ModalCode,
    ModalReset,
    ModalSave,
    Loader,
    SignaturePowered,
  },
  data() {
    const domain = window.location.origin;
    const basePath = this.basePath.endsWith('/') ? this.basePath : `${this.basePath}/`;
    return {
      showingToast: false,
      toastMessage: '',
      toastTimeout: null,
      isMobile: window.innerWidth < 997,
      previewEmail: '',
      isLoading: false,
      entryTime: null,
      clickCount: 0,
      changeCount: 0,
      showHeader: true,
      showModal: false,
      showCodeModalFlag: false,
      showResetModal: false,
      showSaveModalFlag: false,
      isDarkMode: false,
      navOpen: false,
      isPreview: false,
      isSettings: false,
      isDev: false,
      generatedCode: '',
      formData: {
        ...{
          name: 'Maria Rodriguez',
          jobTitle: 'Support Team',
          companyName: 'Acme Inc.',
          companyAddress: 'Suite 52 66425 Lang Stream, Jorgeshire, ME 81915-5433',
          phoneNumber: '12 345 6789',
          phoneLabel: 'T:',
          mobileNumber: '(234) 121-7878',
          mobileLabel: 'T:',
          emailAddress: 'maria.rodriguez@acme.com',
          emailLabel: 'E:',
          showProfileImage: true,
          showLogo: true,
          disclaimerWidth: false,
          logoSize: 100,
          socialIconSize: 16,
          socialIconShape: 50,
          plannedAbsenceColor: '#F6F6F6',
          plannedAbsenceTextColor: '#6B6B6B',
          link: {
            name: '',
            url: '',
          },
          banner: {
            url: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-13T17:37:33.710Z_247675d4-c819-4e3b-acd3-303011527a7e',
            href: '',
          },
          feedback: {
            question: '',
            likeIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:43:02.337Z_18660755-126d-41eb-827e-0b6d751fb232',
            likeLink: `${domain}${basePath}positive`,
            dislikeIcon: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:43:13.412Z_bd177bcd-2cea-4537-9ed9-e970eb8f3e79',
            dislikeLink: `${domain}${basePath}negative`,
          },
          plannedAbsence: {
            text: `I'll be out of the office on:`,
            date: 'November 15 - November 30, 2024',
          },
          disclaimer: 'The content of this email is intended for the person or entity to which it is addressed only. This email may contain confidential information. If you are not the person to whom this message is addressed, be aware that any use, reproduction, or distribution of this message is strictly prohibited. If you received this in error, please contact the sender and immediately delete this email and any attachments.',
          profileImage: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T13:32:16.789Z_cf3b3671-36bb-4356-96ee-8be1d33d1fb6',
          profileImageHref: '',
          logoImage: 'https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-19T10:41:23.493Z_afe1353a-a95d-4b34-8d35-ef49fab4cf22',
          logoImageHref: '',
          additionalFields: [],
          selectedIcons: ['facebook', 'x', 'instagram'],
          socialLinks: {
            facebook: 'https://www.facebook.com',
            x: 'https://www.x.com',
            instagram: 'https://www.instagram.com',
          },
          customIcons: [],
          isWhiteIcons: true,
        },
        ...templatesFormData.template1,
      },
      history: [],
      redoStack: [],
    }
  },
  computed: {
    hasFormData() {
      return (
        this.formData.name ||
        this.formData.jobTitle ||
        this.formData.companyName ||
        this.formData.companyAddress ||
        this.formData.phoneNumber ||
        this.formData.phoneLabel ||
        this.formData.mobileNumber ||
        this.formData.mobileLabel ||
        this.formData.emailAddress ||
        this.formData.emailLabel ||
        this.formData.additionalFields.length > 0
      );
    },
    isDevMode() {
      return this.$route.path.includes('/dev');
    },
    currentLogo() {
      return this.isDarkMode
        ? require('../../assets/images/logo/logo_gsignature_light.svg')
        : require('../../assets/images/logo/logo_gsignature_dark.svg');
    },
    currentIconLinks() {
      return this.formData.isWhiteIcons ? this.formData.iconLinksWhite : this.formData.iconLinksBlack;
    },
    canUndo() {
      return this.history.length > 0;
    },
    canRedo() {
      return this.redoStack.length > 0;
    }
  },
  setup() {
    const router = useRouter();
    return { basePath: router.options.history.base };
  },
  methods: {
    showToast(message) {
      this.toastMessage = message;
      this.showingToast = true;

      if (this.toastTimeout) {
        clearTimeout(this.toastTimeout);
      }

      this.toastTimeout = setTimeout(() => {
        this.hideToast();
      }, 2000);
    },
    hideToast() {
      this.showingToast = false;
      this.toastMessage = '';
      if (this.toastTimeout) {
        clearTimeout(this.toastTimeout);
      }
    },
    updateCurrentDate() {
      const currentDate = new Date();
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      this.formData.date = currentDate.toLocaleDateString('en-US', options);
    },
    checkIfPreview() {
      const currentPath = this.$route.path;
      this.isPreview = currentPath.includes('/preview');
    },
    checkIfDev() {
      const currentPath = this.$route.path;
      this.isDev = currentPath.includes('/dev');
    },
    checkIfSettings() {
      const currentPath = this.$route.path;
      this.isSettings = currentPath.includes('/settings');
    },
    generatePreviewLink() {
      const currentPath = window.location.pathname;
      return currentPath.replace('/dev/', '/preview/');
    },
    generateEditLink() {
      const currentPath = window.location.pathname;
      return currentPath.replace('/dev/', '/settings/');
    },
    navigateTo(path) {
      if (path) {
        window.open(path, '_blank');
      }
    },
    async loadSettingsFromId(id) {
      this.isLoading = true;
      try {
        const url = `${BASE_URL}get-signature?id=${id}`;
        const response = await axios.get(url);
        if (response.status !== 200) {
          throw new Error('Failed to load settings');
        }
        const data = response.data;
        if (data.error) {
          console.error('Error from server:', data.error);
        } else {
          this.formData = { ...this.formData, ...data.settings };
          this.previewEmail = data.email;
        }
      } catch (error) {
        console.error('Error loading settings:', error);
      } finally {
        this.isLoading = false;
      }
    },
    toggleIconColor() {
      this.formData.isWhiteIcons = !this.formData.isWhiteIcons;
    },
    toggleNav() {
      this.navOpen = !this.navOpen;
      const topNav = document.querySelector('.top-nav');

      if (this.navOpen && topNav) {
        topNav.classList.remove('hidden');
        topNav.classList.add('visible');
        document.body.style.overflow = 'hidden';
        document.querySelector('.editor').style.overflow = 'hidden';
      } else if (topNav) {
        topNav.classList.remove('visible');
        topNav.classList.add('hidden');
        document.body.style.overflow = '';
        document.querySelector('.editor').style.overflow = '';
      }
    },
    handleNavClick() {
      if (this.isMobile) {
        this.toggleNav();
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth < 997;
      const topNav = document.querySelector('.top-nav');
      if (this.isMobile && topNav) {
        this.navOpen = false;
        topNav.classList.add('hidden');
      }
    },
    updateFormData(data) {
      this.saveToHistory();
      this.formData = {
        ...this.formData,
        ...data,
      };
    },
    handleTemplateSelected(templateName) {
      if (templatesFormData[templateName]) {
        this.saveToHistory();
        this.formData = {
          ...this.formData,
          ...templatesFormData[templateName],
        };
      }
    },
    addCustomIcon(icon) {
      this.saveToHistory();
      this.formData.customIcons.push(icon);
    },
    updateProfileImage(imageUrl) {
      this.saveToHistory();
      this.formData.profileImage = imageUrl;
    },
    updateSelectedIcons(icons) {
      this.saveToHistory();
      this.formData.selectedIcons = icons;
    },
    toggleSocialIconText(socialIconText) {
      this.saveToHistory();
      this.formData.socialIconText = socialIconText;
    },
    updateSocialLinks(links) {
      this.saveToHistory();
      this.formData.socialLinks = links;
    },
    updateTextColor(color) {
      this.saveToHistory();
      this.formData.mainColor = color;
    },
    updateMainColor(color) {
      this.saveToHistory();
      this.formData.mainColor = color;
    },
    updateSecondaryColor(color) {
      this.saveToHistory();
      this.formData.secondaryColor = color;
    },
    updateDescriptionBackground(color) {
      this.saveToHistory();
      this.formData.descriptionBackground = color;
    },
    updateBorderColor(color) {
      this.saveToHistory();
      this.formData.borderColor = color;
    },
    updateSocialIconColor(color) {
      this.saveToHistory();
      this.formData.socialIconColor = color;
    },
    updatePlannedAbsenceColor(color) {
      this.saveToHistory();
      this.formData.plannedAbsenceColor = color;
    },
    updatePlannedAbsenceTextColor(color) {
      this.saveToHistory();
      this.formData.plannedAbsenceTextColor = color;
    },
    applySignatureWidth(signatureWidth) {
      this.saveToHistory();
      this.formData.signatureWidth = signatureWidth;
    },
    toggleProfileImage(showProfileImage) {
      this.saveToHistory();
      this.formData.showProfileImage = showProfileImage;
    },
    applyProfileImageSize(profileImageSize) {
      this.saveToHistory();
      this.formData.profileImageSize = profileImageSize;
    },
    applyProfileImageShape(profileImageShape) {
      this.saveToHistory();
      this.formData.profileImageShape = profileImageShape;
    },
    applySocialIconShape(socialIconShape) {
      this.saveToHistory();
      this.formData.socialIconShape = socialIconShape;
    },
    updateLogoImage(imageUrl) {
      this.saveToHistory();
      this.formData.logoImage = imageUrl;
    },
    toggleLogo(showLogo) {
      this.saveToHistory();
      this.formData.showLogo = showLogo;
    },
    toggleDisclaimer(disclaimerWidth) {
      this.saveToHistory();
      this.formData.disclaimerWidth = disclaimerWidth;
    },
    applyLogoSize(logoSize) {
      this.saveToHistory();
      this.formData.logoSize = logoSize;
    },
    updateProfileImageHref(href) {
      this.saveToHistory();
      this.formData.profileImageHref = href;
    },
    updateLogoImageHref(href) {
      this.saveToHistory();
      this.formData.logoImageHref = href;
    },
    applySocialIconSize(size) {
      this.saveToHistory();
      this.formData.socialIconSize = size;
    },
    applyFontFamily(fontFamily) {
      this.saveToHistory();
      this.formData.fontFamily = fontFamily;
    },
    applyFontSize(fontSize) {
      this.saveToHistory();
      this.formData.fontSize = fontSize;
    },
    addAdditionalField(fields) {
      this.saveToHistory();
      if (fields) {
        this.formData.additionalFields = fields;
      } else {
        this.formData.additionalFields.push({
          label: `Text ${this.formData.additionalFields.length + 1}`,
          value: '',
          href: '',
          hrefType: 'url'
        });
      }
    },
    removeAdditionalField(index) {
      this.saveToHistory();
      this.formData.additionalFields.splice(index, 1);
    },
    updateFieldValue(index, value) {
      this.saveToHistory();
      this.formData.additionalFields[index].value = value;
    },
    updateSelectedTemplate(templateName) {
      this.saveToHistory();
      this.formData.selectedTemplate = templateName;
    },
    toggleDarkMode() {
      this.saveToHistory();
      this.isDarkMode = !this.isDarkMode;
    },
    saveSignature() {
      const template = this.$refs.signaturePreview.$el.innerHTML;
      const blob = new Blob([template], { type: 'text/html;charset=utf-8' });
      saveAs(blob, 'signature.html');
    },
    convertRgbToHex(color) {
      if (!color.startsWith('rgb')) return color;

      const rgb = color.match(/\d+/g);
      if (!rgb) return color;

      const hex = rgb
        .map((num) => parseInt(num, 10).toString(16).padStart(2, '0'))
        .join('');
      return `#${hex}`;
    },
    processHtml(html) {
      return html.replace(/rgb\((\d+), (\d+), (\d+)\)/g, (_, r, g, b) => {
        return this.convertRgbToHex(`rgb(${r}, ${g}, ${b})`);
      });
    },
    updateFormDataForExport() {
      this.originalFormData = { ...this.formData };

      this.formData.name = this.formData.name ? '${userName}' : '';
      this.formData.jobTitle = this.formData.jobTitle ? '${jobTitle}' : '';
      this.formData.companyName = this.formData.companyName ? '${companyName}' : '';
      this.formData.companyAddress = this.formData.companyAddress ? '${companyAddress}' : '';
      this.formData.logoImage = this.formData.logoImage ? '${companyImageUrl}' : '';
      this.formData.phoneNumber = this.formData.phoneNumber ? '${labelPhoneNumber}' : '';
      this.formData.mobileNumber = this.formData.mobileNumber ? '${labelMobileNumber}' : '';
      this.formData.emailAddress = this.formData.emailAddress ? '${labelUserEmail}' : '';
      this.formData.profileImage = this.formData.profileImage ? '${userThumbnailUrl}' : '';
      this.formData.plannedAbsence.text = '';
      this.formData.plannedAbsence.date = '';
      this.formData.banner.url = '';
      this.formData.disclaimer = '';
      this.formData.phoneLabel = '';
      this.formData.phoneIcon = '';
      this.formData.mobileLabel = '';
      this.formData.mobileIcon = '';
      this.formData.emailLabel = '';
      this.formData.emailIcon = '';

      this.$nextTick(() => {
      this.generateCode();
    });
    },
    restoreOriginalFormData() {
      this.formData = { ...this.originalFormData };

      this.$nextTick(() => {
      this.generateCode();
    });
    },
    showSaveModal() {
      this.showSaveModalFlag = true;
    },
    generateCode() {
      const rawHtml = this.$refs.signaturePreview.$el.innerHTML;
      this.generatedCode = this.processHtml(rawHtml);
    },
    hideSaveModalFlag() {
      this.restoreOriginalFormData();
      this.showSaveModalFlag = false;
    },
    showCodeModal() {
      const rawHtml = this.$refs.signaturePreview.$el.innerHTML;
      this.generatedCode = this.processHtml(rawHtml);
      this.showCodeModalFlag = true;
    },
    handleAddField(field) {
      this.saveToHistory();
      this.formData.additionalFields.push({
        title: field.title,
        label: field.label,
        value: field.value,
        icon: field.icon,
        href: field.href,
        hrefType: field.hrefType
      });
    },
    updateAddonsColor(color) {
      this.saveToHistory();
      this.formData.addonsColor = color;
    },
    updateChatColor(color) {
      this.saveToHistory();
      this.formData.chatColor = color;
    },
    updateBannerWidth(width) {
      this.saveToHistory();
      this.formData.bannerWidth = width;
    },
    updateLink(link) {
      this.saveToHistory();
      this.formData.link = link;
    },
    updateBanner(banner) {
      this.saveToHistory();
      this.formData.banner = banner;
    },
    updatePlannedAbsence(plannedAbsence) {
      this.saveToHistory();
      this.formData.plannedAbsence = plannedAbsence;
    },
    updateDisclaimer(disclaimer) {
      this.saveToHistory();
      this.formData.disclaimer = disclaimer;
    },
    updateFeedback(feedback) {
      this.saveToHistory();
      this.formData.feedback = feedback;
    },
    updateMeetingChat(meetingChat) {
      this.saveToHistory();
      this.formData.meetingChat = meetingChat;
    },
    updateMeetingWidth(width) {
      this.saveToHistory();
      this.formData.meetingWidth = width;
    },
    handleConfirmReset() {
      this.resetSettings();
      this.showResetModal = false;
    },
    confirmResetSettings() {
      this.showResetModal = true;
    },
    resetSettings() {
      this.formData = JSON.parse(JSON.stringify(this.defaultFormData));
      this.isDarkMode = false;
      this.navOpen = false;
    },
    saveToHistory() {
      this.history.push(JSON.parse(JSON.stringify(this.formData)));
      this.redoStack = [];
    },
    undo() {
      if (this.canUndo) {
        const lastState = this.history.pop();
        this.redoStack.push(JSON.parse(JSON.stringify(this.formData)));
        this.formData = lastState;
      }
    },
    redo() {
      if (this.canRedo) {
        const nextState = this.redoStack.pop();
        this.history.push(JSON.parse(JSON.stringify(this.formData)));
        this.formData = nextState;
      }
    }
  },
  watch: {
    '$route'(to) {
      this.checkIfPreview();
      this.checkIfDev();
      this.checkIfSettings();

      const hideHeaderPaths = [
        '/basic-info',
        '/signature-options',
        '/social-media',
        '/signature-templates',
      ];
      this.showHeader = !hideHeaderPaths.includes(to.path);
    },
    isDarkMode(newVal) {
      if (newVal) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  created() {
    this.entryTime = Date.now();

    this.defaultFormData = JSON.parse(JSON.stringify(this.formData));

    this.updateCurrentDate();

    this.checkIfPreview();
    this.checkIfDev();
    this.checkIfSettings();

    const id = this.$route.params.id;
    if (id) {
      this.loadSettingsFromId(id);
    }
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background: #f4f4f9;
  color: #333;
  transition: color 0.5s;
  height: 100%;
}

.dark-mode body {
  background: #181818;
}

.content .loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(135deg, #ece9e6, #ffffff);
}

.dark-mode {
  background: #181818;
  color: #ffffff;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.editor, .preview {
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 1rem;
}

.editor{
  margin: 0.5rem 0 0.5rem 0.5rem;
  padding-top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #f0f0f0;
}

.preview.isPreview .reset-settings{
  display: none;
}

.preview{
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.dark-mode .editor, .dark-mode .preview {
  background: #2c2c2c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.top-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 1rem 0;
  position: sticky;
  top: 0;
  background: inherit;
  z-index: 10;
  width: 100%;
}

.nav-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: #e0e0e0;
  color: #333;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
  flex: 1;
  min-width: 120px;
}

.nav-tab.router-link-exact-active,
.nav-tab:hover {
  background: #d0d0d0;
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-tab.code-button {
  background: #FF6A00;
  color: #ffffff;
}

.nav-tab.code-button:hover {
  background: #FF6A00;
}

.nav-tab.toggle-dark-mode {
  background: #28c840;
  color: #ffffff;
}

.nav-tab.toggle-dark-mode:hover {
  background: #24b43a;
}

.dark-mode .nav-tab {
  background: #3c3c3c;
  color: #ddd;
}

.dark-mode .nav-tab.router-link-exact-active,
.dark-mode .nav-tab:hover {
  background: #4c4c4c;
}

.dark-mode .nav-tab.code-button {
  background: #c15000;
}

.dark-mode .nav-tab.code-button:hover {
  background: #c15000;
}

.dark-mode .nav-tab.toggle-dark-mode {
  background: #24b43a;
}

.dark-mode .nav-tab.toggle-dark-mode:hover {
  background: #209f33;
}

.header {
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
  margin: 0;
  color: #333;
  transition: color 0.5s;
}

p {
  font-size: 1rem;
  margin: 0;
  color: #666;
  transition: color 0.5s;
}

a {
  color: #FF6A00;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-tab.router-link-exact-active,
a:hover {
  color: #FF6A00;
}

.dark-mode h2, .dark-mode p, .dark-mode a {
  color: #ffffff;
}

.dark-mode a:hover {
  color: #c15000;
}

.email-client {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;
}

.dark-mode .email-client {
  border: 1px solid #555;
}

.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background: #e0e0e0;
  border-bottom: 1px solid #ddd;
}

.dark-mode .title-bar {
  background: #3c3c3c;
  border-bottom: 1px solid #555;
}

.buttons {
  display: flex;
  gap: 0.5rem;
}

.close, .minimize, .maximize {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: transform 0.2s, box-shadow 0.2s;
}

.close {
  background-color: #ff5f56;
}

.minimize {
  background-color: #ffbd2e;
}

.maximize {
  background-color: #28c840;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  font-size: 14px;
  opacity: 0.9;
  transition: all 0.6s;
}

.toast.hide {
  opacity: 0;
  transition: all 0.6s;
}

.undo-button, .redo-button, .reset-settings, .dark-mode-toggle {
  cursor: pointer;
  background-color: #e0e0e0;
  border-radius: 50%;
  padding: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, transform 0.3s;
}

.undo-button:hover, .redo-button:hover, .reset-settings:hover, .dark-mode-toggle:hover {
  background-color: #c0c0c0;
  transform: scale(1.1);
}

.undo-button i, .redo-button i, .reset-settings i, .dark-mode-toggle i {
  color: #333;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.disabled i {
  color: #aaa;
}

.disabled:hover {
  background-color: #e0e0e0;
  transform: none;
}

.dark-mode .undo-button, .dark-mode .redo-button, .dark-mode .reset-settings, .dark-mode .dark-mode-toggle {
  background-color: #3c3c3c;
}

.dark-mode .undo-button:hover, .dark-mode .redo-button:hover, .dark-mode .reset-settings:hover, .dark-mode .dark-mode-toggle:hover {
  background-color: #4c4c4c;
}

.dark-mode .undo-button i, .dark-mode .redo-button i, .dark-mode .reset-settings i, .dark-mode .dark-mode-toggle i {
  color: #ddd;
}


.dark-mode .disabled i {
  color: #666;
}

.dark-mode .disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.dark-mode .disabled:hover {
  background-color: #3c3c3c;
  transform: none;
}

.actions {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dark-mode .close {
  background-color: #ff5f56;
}

.dark-mode .minimize {
  background-color: #ffbd2e;
}

.dark-mode .maximize {
  background-color: #28c840;
}

.title {
  font-weight: bold;
  color: #333;
}

.dark-mode .title {
  color: #fff;
}

.toolbar {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 1rem;
  background: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.dark-mode .toolbar {
  background: #3c3c3c;
  border-bottom: 1px solid #555;
}

.toolbar i {
  color: #555;
  transition: color 0.3s, transform 0.3s;
}

.dark-mode .toolbar i {
  color: #ddd;
}

.email-header {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  background: #fff;
}

.dark-mode .email-header {
  background: #3c3c3c;
  border-bottom: 1px solid #555;
}

.email-from, .email-to, .email-subject, .email-date {
  margin-bottom: 0.5rem;
  color: #555;
}

.dark-mode .email-from, .dark-mode .email-to, .dark-mode .email-subject, .dark-mode .email-date {
  color: #ddd;
}

.email-body {
  padding: 1rem;
  background: #fefefe;
  flex: 1;
  overflow-y: auto;
}

.dark-mode .email-body {
  background: #4c4c4c;
}

.editor::-webkit-scrollbar, .preview::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.editor::-webkit-scrollbar-track, .preview::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 6px;
}

.editor::-webkit-scrollbar-thumb, .preview::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 6px;
  border: 3px solid #f0f0f0;
}

.editor::-webkit-scrollbar-thumb:hover, .preview::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

.dark-mode .editor, .dark-mode .preview {
  scrollbar-color: #6b6b6b #2c2c2c;
}

.dark-mode .editor::-webkit-scrollbar-track, .dark-mode .preview::-webkit-scrollbar-track {
  background: #2c2c2c;
}

.dark-mode .editor::-webkit-scrollbar-thumb, .dark-mode .preview::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
  border: 3px solid #2c2c2c;
}

.dark-mode .editor::-webkit-scrollbar-thumb:hover, .dark-mode .preview::-webkit-scrollbar-thumb:hover {
  background-color: #4c4c4c;
}

.hamburger {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.2rem 0.5rem;
  background-color: #ffffff;
  border: 1px solid #4c4c4c;
  border-radius: 5px;
  z-index: 100;
}

.dark-mode .hamburger {
  background-color: #2c2c2c;
}

@media (min-width: 997px) {
  .logo{
    display: none;
  }

  .content {
    flex-direction: row;
  }

  .editor {
    flex: 1;
    max-width: 30%;
  }

  .preview {
    flex: 2;
    margin-left: 1rem;
  }

  .nav-tab {
    padding: 0.75rem 1.5rem;
  }
}

@media (max-width: 480px) {
  .nav-tab {
    flex: 1 1 100%;
  }

  .header h2 {
    font-size: 1rem;
  }

  .header p {
    font-size: 0.75rem;
  }
}

@media (max-width: 997px) {
    @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  .powered-container {
    display: none;
  }

  .logo{
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    height: 45px;
  }

  .app{
    height: unset;
  }

  .content {
    flex-direction: column;
    overflow-y: auto;
  }

  .editor {
    order: 2;
    margin: 0.5rem 0 1rem 0;
    padding-top: 1rem;
    overflow: hidden;
  }

  .preview {
    order: 1;
    margin: 4rem 0 0.5rem 0;
  }

  .preview.isPreview {
    margin: 0.5rem;
    margin-top: 4rem;
    height: calc(100vh - 7rem);
  }

  .editor .preview{
    height: auto;
    flex: 1 0 auto;
    overflow-y: hidden;
  }

  .title-bar, .toolbar, .email-header, .email-body {
    padding: 0.5rem;
  }

  .header h2 {
    font-size: 1.25rem;
  }

  .header p {
    font-size: 0.875rem;
  }

  .hamburger {
    display: block;
  }

  .top-nav:not(.hidden) {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start
  }

  .top-nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 60%;
    background: #ffffff;
    z-index: 1000;
    flex-wrap: nowrap;
    padding: 1rem;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .dark-mode .top-nav {
    background: #6b6b6b;
  }

  .top-nav a, .top-nav button{
    padding: 2rem 0;
    margin: 0.5rem 0;
  }

  .top-nav {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  }

  .top-nav.hidden {
    transform: translateX(-100%);
    opacity: 0;
  }

  .top-nav.visible {
    transform: translateX(0);
    opacity: 1;
  }

  .top-nav.hidden a, .top-nav.hidden button{
    display: none;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    transition: opacity 0.3s;
  }

  .hamburger {
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 1100;
  }
}
</style>
